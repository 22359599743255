import {AppletContext, PluginContext, UserCache} from "./types";
import {JsonObject} from "./json/json-object";
import {JsonProperty} from "./json/json-property";
import {ProvisioningContext, Rel, SystemProvisioningIds} from "./database";
import {Auth, getAuth} from "@firebase/auth";
import {JSON_OBJECT} from "./json/helpers";
import {remove} from "@firebase/database";
import {md5_uuid} from "./md5";
import {BaseApp, ContextType, getProvisioningId} from "./BaseApp";
import {FormGen} from "./formgen";

@JsonObject()
export class IdType {

  constructor(@JsonProperty() readonly id: string, @JsonProperty() readonly type: string) {
  }
}

export enum EntityType {
  USER = "user",
}

@JsonObject()
export abstract class Entity {

  @JsonProperty()
  firstname: string;
  @JsonProperty()
  lastname: string;
  @JsonProperty()
  profilePhoto: string;

  protected constructor(firstname?: string, lastname?: string, profilePhoto?: string) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.profilePhoto = profilePhoto;
  }
}

@JsonObject()
export abstract class BaseUser extends Entity {

  @JsonProperty()
  uid: string;

  protected constructor(uid: string, firstname: string, lastname: string, profilePhoto: string) {
    super(firstname, lastname, profilePhoto);
    this.uid = uid;
  }
}

@JsonObject()
export class User extends BaseUser {

  constructor(uid: string, firstname: string, lastname: string, profilePhoto?: string,
              @JsonProperty() readonly email?: string,
              @JsonProperty() readonly created?: number,
              @JsonProperty() readonly phoneNumber?: string,
              @JsonProperty() readonly isEmployee?: boolean,
              @JsonProperty() readonly isTest?: boolean) {
    super(uid, firstname, lastname, profilePhoto);
  }
}

export class UserBuilder {

  @FormGen({name: "UID", type: "copyable"})
  readonly uid: string;
  @FormGen({name: "Firstname", type: "string"})
  firstname: string;
  @FormGen({name: "Lastname", type: "string"})
  lastname: string;
  @FormGen({name: "Profile photo", type: "profile_photo"})
  profilePhoto: string;
  @FormGen({name: "Email", type: "string"})
  email: string;
  created: number;
  @FormGen({name: "Phone number", type: "string"})
  phoneNumber: string;
  @FormGen({name: "Is employee", type: "boolean"})
  isEmployee: boolean;
  @FormGen({name: "Is test", type: "boolean"})
  isTest: boolean;

  constructor(user: User) {
    this.uid = user.uid;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.profilePhoto = user.profilePhoto;
    this.email = user.email;
    this.created = user.created;
    this.phoneNumber = user.phoneNumber;
    this.isEmployee = user.isEmployee;
    this.isTest = user.isTest;
  }

  build(): User {
    return new User(this.uid, this.firstname, this.lastname, this.profilePhoto, this.email, this.created, this.phoneNumber, this.isEmployee, this.isTest);
  }
}

@JsonObject()
export abstract class ContainerKey {

  @JsonProperty()
  readonly _parts: string[];

  protected constructor(...parts: string[]) {
    this._parts = parts?.filter(part => Boolean(part)) || [];
  }

  static equals(key1: ContainerKey, key2: ContainerKey) {
    return (key1?._parts || []).join("/") === (key2?._parts || []).join("/");
  }

  nonDefaultOrNull() {
    if (this._parts?.length > 0) {
      return this;
    }
    return null;
  }

  path(): string {
    if (!(this._parts?.length > 0)) {
      return "";
    }
    return "/" + this._parts.join("/");
  }
}

@JsonObject()
export class AccessControlActionData {

  static createNew() {
    return new AccessControlActionData(md5_uuid());
  }

  @JsonProperty()
  readonly id: string;

  @JsonProperty()
  expires: number = -1;

  constructor(id: string) {
    this.id = id;
  }
}

@JsonObject()
export class AccessControlAction {

  @JsonProperty()
  read: AccessControlActionData[];

  @JsonProperty()
  write: AccessControlActionData[];
}

@JsonObject()
export class AccessControlAllowList {

  @JsonProperty()
  actions: AccessControlAction[];
}

@JsonObject()
export class AccessControlDenyList {

  @JsonProperty()
  actions: AccessControlAction[];
}

@JsonObject()
export class AccessControlList {

  @JsonProperty()
  allow: AccessControlAllowList[];

  @JsonProperty()
  deny: AccessControlDenyList[];
}

@JsonObject()
export class MembersKey extends ContainerKey {

  static readonly DEFAULT = new MembersKey();

  static fromProvisioningContext() {
    return new MembersKey();
  }

  static from(id: string) {
    return new MembersKey(id);
  }

  constructor(...parts: string[]) {
    super(...parts);
  }
}

@JsonObject()
export class Member {

  user?: User;

  acl?: AccessControlList; // Only set if member.isMe() is true.

  @JsonProperty()
  uid: string;

  @JsonProperty()
  memberId: string;

  @JsonProperty()
  key: MembersKey;

  @JsonProperty()
  passwordUpdateRequired?: boolean;

  constructor(memberId: string, uid: string, key: MembersKey) {
    this.memberId = memberId;
    this.uid = uid;
    this.key = key;
  }

  isMe(): boolean {
    const auth = getAuth();
    if (!auth.currentUser) {
      return false;
    }
    return auth.currentUser.uid === this.uid;
  }

  static readonly USERS_REL_NAME = "users";

  usersRel() {
    return new Rel(Member.USERS_REL_NAME, this.uid, this.memberId);
  }
}

export class Members {

  private static instances: Map<string, Members> = new Map<string, Members>();

  static getInstance(key?: MembersKey) {
    if (!key) {
      const contextType = BaseApp.CONTEXT.contextType();
      if (contextType === ContextType.PLUGIN) {
        const pluginContext = BaseApp.CONTEXT as PluginContext;
        // TODO: See if Bevie plugins require seaprate plugin id for member key, which scopes member data when plugin
        // app is running in standalone mode.
        key = pluginContext.getPluginConfig()?.membersKey || MembersKey.DEFAULT;//MembersKey.from(pluginContext.getPluginId());
      } else if (contextType === ContextType.APPLET) {
        const appletContext = BaseApp.CONTEXT as AppletContext;
        key = appletContext.getAppletConfig()?.membersKey || MembersKey.from(appletContext.getAppletId());
      } else {
        key = MembersKey.DEFAULT;
      }
    }
    let instance = this.instances.get(key.path());
    if (!instance) {
      instance = new Members(key);
      this.instances.set(key.path(), instance);
    }
    return instance;
  }

  private readonly auth = getAuth();
  private readonly provisioningContext: ProvisioningContext;
  private members: Member[] = [];

  private constructor(readonly key: MembersKey) {
    // We always use main provisioning for inhouse members.
    this.provisioningContext = getProvisioningId() === SystemProvisioningIds.INHOUSE ? ProvisioningContext.MAIN : ProvisioningContext.DEFAULT;
  }

  async loadMembers(force?: boolean): Promise<void> {
    if (this.members && !force) {
      return;
    }
    const val = await this.provisioningContext.dbRef_getVal("members" + this.key.path());
    const members: Member[] = [];
    if (val) {
      for (const key in val) {
        const member = await this.loadMember(key);
        if (member) {
          members.push(member);
        }
      }
    }
    const myUid = this.auth.currentUser.uid;
    this.members = members.filter(member => member.user.uid !== myUid);
  }

  async getOrLoadMembers(): Promise<Member[]> {
    if (!this.members) {
      await this.loadMembers();
    }
    return Promise.resolve(this.members || []);
  }

  getMembers(): Member[] {
    return this.members;
  }

  getMember(memberId: string): Member | undefined {
    return this.members?.find(value => value?.memberId === memberId);
  }

  async loadMember(memberId: string): Promise<Member | null> {
    const value = await this.provisioningContext.dbRef_getVal("members" + this.key.path() + "/" + memberId);
    if (value) {
      const member = JSON_OBJECT.deserializeObject(value, Member);
      member.user = await UserCache.getInstance().getUser(member.uid);
      if (member.isMe()) {
        const value = await this.provisioningContext.dbRef_getVal("acls" + this.key.path() + "/" + memberId);
        if (value) {
          member.acl = JSON_OBJECT.deserializeObject(value, AccessControlList);
        }
      }
      return member;
    }
    return null;
  }

  async getOrLoadMember(memberId: string): Promise<Member | null> {
    let member = this.getMember(memberId);
    if (!member) {
      member = await this.loadMember(memberId);
      this.members.push(member);
    }
    return member;
  }

  async loadMemberByUid(uid: string): Promise<Member | null> {
    const value = await this.provisioningContext.dbRef_getVal("members" + this.key.path() + Rel.oneToOnePathFor(Member.USERS_REL_NAME, uid));
    if (value) {
      return this.loadMember(value);
    }
    return Promise.resolve(null);
  }

  getMemberByUid(uid: string): Member | undefined {
    return this.members?.find(value => value.user?.uid === uid);
  }

  async getOrLoadMemberByUid(uid?: string, create?: boolean): Promise<Member | undefined> {
    if (!uid && create) {
      // Guest uid.
      uid = md5_uuid();
    }
    let member = this.getMemberByUid(uid);
    if (!member) {
      member = await this.loadMemberByUid(uid);
    }
    if (!member && create) {
      member = new Member(md5_uuid(), uid, this.key);
      await this.addMember(member);
    }
    return member;
  }

  async addMemberAccessControl(member: Member, access: keyof AccessControlList, action: keyof AccessControlAction, key: string, expires: number = -1): Promise<void> {
    const data = AccessControlActionData.createNew();
    data.expires = expires;
    const object = JSON_OBJECT.serializeObject(data);
    return this.provisioningContext.dbRef_setVal("members" + this.key.path() + "/" + member.memberId + "/" + access + "/" + action + "/" + key, object);
  }

  async addMember(member: Member): Promise<void> {
    const object = JSON_OBJECT.serializeObject(member);
    // console.trace("adding member at: " + "members" + this.key.path() + "/" + member.memberId);
    return this.provisioningContext.dbRef_setVal("members" + this.key.path() + "/" + member.memberId, object, member.usersRel());
  }

  async setMemberPart(memberId: string, partName: keyof Member, part: object): Promise<void> {
    const object = JSON_OBJECT.serializeObject(part);
    return this.provisioningContext.dbRef_setVal("members" + this.key.path() + "/" + memberId + "/" + partName, object);
  }

  async deleteMember(auth: Auth, member: Member): Promise<void> {
    const aclRef = this.provisioningContext.dbRef("acls" + this.key.path() + "/" + member.memberId);
    try {
      await remove(aclRef);
    } catch (e) {
      // Continue.
    }
    const memberRef = this.provisioningContext.dbRef("members" + this.key.path() + "/" + member.memberId);
    return remove(memberRef);
  }
}
