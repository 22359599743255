import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "../shared/BaseFragment";

export type MainProps = BaseFragmentProps & {}

type MainState = BaseFragmentState & {}

export class Main extends BaseFragment<MainProps, MainState> {

  private static loaded;

  constructor(props: MainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  componentDidMount() {
    super.componentDidMount();
    setTimeout(() => this.load(), 1);
  }

  private async load() {
    if (Main.loaded) {
      return;
    }
    Main.loaded = true;
    const html = await fetch("/__build/index.htmlpart")
      .then(resp => resp.text());
    const parser = new DOMParser();
    // Parse the text
    const doc = parser.parseFromString(html, "text/html");
    for (const child of document.getElementById("main").children) {
      child.remove();
    }
    const boot = await fetch("/__build/pixelduck-boot.js")
      .then(resp => resp.text());
    const script = document.createElement("script");
    script.text = boot;
    const main = document.getElementById("main");
    main.append(...doc.body.children, script);
    main.style.display = "block";
  }
}
