import {ThemeOptions} from "@mui/material";
import {BORDER_RADIUS, CARD_SHADOW, PD_MD, PD_SM, PD_XSM} from "./dimens";
import {md5_code} from "./md5";

export const colorDefault = '#ff3300';
export const colorPrimary = '#93278f';
export const colorPrimaryContrastText = '#fff';
export const colorPrimaryDark = '#00aeef';
export const colorAccent = '#fed01b';
// export const colorHighlight = '#FFFCE9';
export const colorHighlight = '#f9f9c5';
export const colorHighlightAlt = '#BAE1ff';
export const colorLink = '#34B7F1';
export const inactiveAddButtonColor = '#B3B3B3';
export const colorWhiteSmoke = '#efefef'
export const colorRed = '#ed1c24';
export const colorLightGreen = '#91c653';
export const colorDarkGreen = '#009588';
export const colorGreen = '#52b257';
export const colorBlue = '#13aaef';
export const colorDarkBlue = '#003366';
export const colorYellow = '#fed01b';
export const colorOrange = '#ffa115';
export const colorPurple = '#c682d2';
export const colorDarkPurple = '#8d309b';
export const colorPink = '#f06292';
export const colorDarkPink = '#8f1537';
export const colorBrown = '#ae8e6c';
export const colorDarkBrown = '#5d3a3a';
export const colorAlabaster = "#f2f0e6";
export const colorGray = '#ccc';
export const dividerDark = '#2fff';
export const white = '#fff';
export const black = '#000';
export const gray = '#eee';
export const lightGray = '#f7f7f7';
export const mediumGray = '#999';
export const mediumDarkGray = '#666';
export const nightGray = '#222';
export const darkGray = '#000';
export const translucentCardLight = '#CFFF';
export const translucentCardDark = '#C000';
export const translucentNavigationLight = '#CFFF';
export const translucentWhite = 'rgba(255, 255, 255, 0.8)';
export const translucentGray = '#33333366';
export const semiTransparentBlack = 'rgba(0, 0, 0, 0.25)';
export const translucentBlack = 'rgba(0, 0, 0, 0.5)';
export const semiOpaqueBlack = 'rgba(0, 0, 0, 0.75)';
export const almostBlack = 'rgba(0, 0, 0, 0.9)';
export const translucentNavigation = '#6000';

export const colorPastelRed = '#ffb3ba';
export const colorPastelOrange = '#ffdfba';
export const colorPastelYellow = '#ffffba';
export const colorPastelGreen = '#baffc9';
export const colorPastelBlue = '#bae1ff';

export const colorEarthRed = '#d64e51';
export const colorEarthOrange = '#e19123';
export const colorEarthYellow = '#fdb55d';
export const colorEarthGreen = '#708a81';
export const colorEarthBlue = '#5d82a6';
export const colorEarthLightBrown = '#e3cfb9';
export const colorEarthDarkGreen = '#054454';
export const colorEarthBrown = '#c09280';

export const colorPhotos = colorLightGreen;

export const pastelColors = [
  colorPastelRed,
  colorPastelOrange,
  colorPastelYellow,
  colorPastelGreen,
  colorPastelBlue,
];

export const brightPaletteColors = [
  colorBlue,
  colorGreen,
  colorRed,
  colorLightGreen,
  colorDarkGreen,
  colorYellow,
  colorOrange,
  colorPurple,
  colorPink,
  colorBrown,
];

export const darkPaletteColors = [
  colorDarkBlue,
  colorDarkPurple,
  colorDarkPink,
  colorDarkBrown,
  lightGray,
  mediumGray,
  darkGray,
  nightGray,
];

export function randomPaletteColor(seed?: string) {
  return brightPaletteColors[md5_code(seed || ("" + Date.now())) % brightPaletteColors.length];
}

export const paletteColors = [
  ...brightPaletteColors,
  ...darkPaletteColors,
];

export class PaletteGradient {

  constructor(readonly name: string, readonly representation: string) {
  }
}

function pg(name: string, ...colors: string[]) {
  return new PaletteGradient(name, "@gradient/" + colors.join(","));
}

export const PaletteGradients = [
  pg("Rave", colorDarkPurple, colorDarkPink),
  pg("Favorite coffee shop", colorBrown, colorDarkBrown),
  pg("Pouring rain", colorBlue, colorLightGreen),
  pg("Beachside camp", colorYellow, colorPink, colorGreen, colorBlue),
  pg("Train journey", colorLightGreen, colorDarkBrown),
  pg("Walk in the park", colorGreen, colorDarkGreen),
  pg("Rainforest expedition", colorDarkGreen, black),
  pg("Misty drive", colorBlue, colorYellow),
  pg("Beautiful sunset", colorRed, colorOrange),
  pg("Calm twilight", colorDarkBlue, colorBlue),
];

function tabStyleOverrides(): any {
  const windowWidth = window.innerWidth;
  if (windowWidth < 440) {
    return {
      minWidth: 56,
      fontSize: "60%",
      paddingRight: 8,
      paddingLeft: 8,
    };
  } else if (windowWidth < 512) {
    return {
      minWidth: 72,
      fontSize: "75%",
      paddingRight: 12,
      paddingLeft: 12,
    };
  } else {
    return {
      minWidth: 96,
      fontSize: "75%",
      paddingRight: 16,
      paddingLeft: 16,
    };
  }
}

export const DEFAULT_THEME_OPTIONS = {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 72,
          fontWeight: "bold",
          borderRadius: BORDER_RADIUS,
          ...tabStyleOverrides(),
        },
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: CARD_SHADOW,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: CARD_SHADOW,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          boxShadow: CARD_SHADOW,
          paddingTop: PD_XSM,
          paddingBottom: PD_XSM,
          paddingLeft: PD_MD,
          paddingRight: PD_MD,
          textTransform: "none",
          // fontWeight: "bold",
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: PD_XSM,
        },
      }
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  typography: {
    h1: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h2: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h3: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h4: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h5: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    h6: {
      fontWeight: "bold",
      lineHeight: 1,
    },
    fontFamily: "Inter, sans-serif",
    fontSize: 12.5,
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#000",
    },
  }
} as ThemeOptions;
